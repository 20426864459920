import React from 'react'

const BannerArea = () => {
  return (
    <section className="banner-area header-bg">
      <div className="container nav-container">
        <div className="banner-wrapper">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="banner-inner desktop-center">
                <h1 className="title">
                  Finding the care that improves lives, every step of the way.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerArea
