import React from 'react'

const CallToAction = () => {
  return (
    <div
      className="call-to-action-section section-bg margin-top-120"
      style={{ backgroundImage: 'url(/img/sections/cta/cta-03.png' }}
    >
      <div className="container nav-container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="theme-section-title white desktop-center">
              <h6 className="subtitle">Best Services and Expert Team</h6>
              <h4 className="title">Exceptional care in the comfort of your own home</h4>
            </div>
            <div className="btn-wrap desktop-center">
              <a href="/services" className="boxed-btn round-btn">
                Discover More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction
