import React from 'react'

const BannerBottomPage = () => {
  return (
    <section className="banner-bottom-section">
      <div className="container nav-container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="banner-bg"
              style={{ backgroundImage: 'url(/img/header/header-03.png)' }}
            >
              <div className="content desktop-center">
                <h6 className="subtitle">Experience the best in-home health services</h6>
                <h2 className="title">Perfect care for the ones you love</h2>
                <div className="button-wrapper">
                  <div className="btn-wrap">
                    <a href="/services" className="boxed-btn round-btn">
                      Our Services
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerBottomPage
