import React from 'react'
import { useViewport } from '@/hooks/useViewport'
import Form from '@/components/Contact/Form'

const ContactArea = () => {
  const { isDesktop, isWidescreen } = useViewport()

  return (
    <section className="contact-home-area margin-top-120 margin-bottom-80">
      <div className="container nav-container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="theme-section-title desktop-center">
              <h6 className="subtitle">Contact Us</h6>
              <h4 className="title">We're here to care for you!</h4>
            </div>
          </div>
        </div>
        <div className="contact-wrapper d-flex justify-content-center">
          <Form />
        </div>
      </div>
    </section>
  )
}

export default ContactArea
