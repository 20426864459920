import Image from '@/components/Image'
import React from 'react'

const WeDoArea = () => {
  return (
    <section className="about-us-section style-01">
      <div className="container nav-container">
        <div className="row">
          <div className="col-lg-6">
            <div className="thumbnail">
              <Image name='about-05.png'/>
              {/* <img src="/img/sections/about/about-05.png" alt="" /> */}
            </div>
          </div>
          <div className="col-lg-6 offset-lg-6">
            <div className="content-wrap">
              <div className="theme-section-title style-01">
                <h6 className="subtitle">WHAT WE DO</h6>
                <h4 className="title">Always ready to provide exceptional care.</h4>
                <p>
                  At Perfect Care, we provide a wide range of personalized care services tailored to
                  the unique needs of each client. Our goal is to help our clients live comfortably
                  and with dignity in their own homes while receiving the care and support they
                  require. Whether you need long-term care services or occasional assistance, our
                  highly trained and reliable team of caregivers is here to help.
                </p>
              </div>
              <div className="check-list-items">
                <ul className="list-parent">
                  <li className="list-child">Physical Therapy</li>
                  <li className="list-child">Occupational Therapy</li>
                  <li className="list-child">Speech Therapy</li>
                </ul>
                <ul className="list-parent">
                  <li className="list-child">Skilled Nursing Care</li>
                  <li className="list-child">Personal Care</li>
                  <li className="list-child">Medical Social Work</li>
                </ul>
              </div>
              <div className="btn-wrap margin-top-30">
                <a href="/services" className="boxed-btn round-btn">
                  Discover More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WeDoArea
