import React from 'react'
import { useLocation } from '@reach/router'

const AboutHome = () => {
  const { pathname } = useLocation()
  return (
    <section className="about-experiece-section margin-top-120">
      <div className="container nav-container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="theme-section-title desktop-center">
              <h6 className="subtitle">Our Experience</h6>

              <h4 className="title text-capitalize">
                We have more than 15 years in providing high-quality home care services{' '}
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className={`icon-box-item ${pathname === '/services' ? 'active' : ''}`}>
              <div className="icon">
                <i className="fas fa-award"></i>
              </div>
              <div className="content">
                <h5 className="title">Quality Services</h5>
                <p>
                  Invaluable in-home assistance that makes daily life as comfortable as possible.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className={`icon-box-item ${
                pathname === '/' || pathname === '/about' ? 'active' : ''
              }`}
            >
              <div className="icon">
                <i className="fas fa-user-friends"></i>
              </div>
              <div className="content">
                <h5 className="title">Professional Team</h5>
                <p>With outstanding skills, forward-thinking, and a dedication to caregiving.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className={`icon-box-item ${pathname === '/contact' ? 'active' : ''}`}>
              <div className="icon">
                <i className="fas fa-stethoscope"></i>
              </div>
              <div className="content">
                <h5 className="title">24/7 Care Available</h5>
                <p>Flexible schedules anytime you need us, day or night.</p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutHome
