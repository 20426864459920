import React from 'react'

const CounterArea = () => {
  return (
    <section className="counter-section-area margin-top-90">
      <div className="container nav-container">
        <div className="counter-section-inner">
          <div className="single-counterup">
            <div className="content-wrap">
              <div className="odo-area">
                <h3 className="odometer odo-title" data-odometer-final="98">
                  95
                </h3>
                <h3 className="title">%</h3>
              </div>
              <div className="content">
                <h6 className="subtitle">Success Rate</h6>
              </div>
            </div>
          </div>
          <div className="single-counterup">
            <div className="content-wrap">
              <div className="odo-area">
                <h3 className="odometer odo-title" data-odometer-final="25">
                  5
                </h3>
                <h3 className="title">+</h3>
              </div>
              <div className="content">
                <h6 className="subtitle">Services Available</h6>
              </div>
            </div>
          </div>
          <div className="single-counterup">
            <div className="content-wrap">
              <div className="odo-area">
                <h3 className="odometer odo-title" data-odometer-final="21">
                  15
                </h3>
                <h3 className="title">+</h3>
              </div>
              <div className="content">
                <h6 className="subtitle">Years Of Experience</h6>
              </div>
            </div>
          </div>
          <div className="single-counterup">
            <div className="content-wrap">
              <div className="odo-area">
                <h3 className="odometer odo-title" data-odometer-final="576">
                  3000
                </h3>
                <h3 className="title">+</h3>
              </div>
              <div className="content">
                <h6 className="subtitle">Happy Clients</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CounterArea
