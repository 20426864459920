import React from 'react'
import { useLocation } from '@reach/router'
import Image from '@/components/Image'

const ChooseUs = () => {
  return (
    <section
      className={`why-choose-us-section ${
        useLocation().pathname !== '/about' ? 'margin-top-90' : ''
      }`}
    >
      <div className="container nav-container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="theme-section-title white desktop-center">
              <h6 className="subtitle">why choose Us</h6>
              <h4 className="title">The Best Solution for Your Home Healthcare Needs</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <ul className="nav nav-pills">
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#accesibility"
                >
                  Accessibility
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link active"
                  data-bs-toggle="pill"
                  data-bs-target="#familiarity"
                >
                  Familiarity
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#trained-staff"
                >
                  Highly trained staff
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade" id="accesibility">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content-wrapper">
                  <h3 className="title">Quality healthcare without leaving your home.</h3>
                  {/*    <h6 className="consultant">Therapy With Dr. Nicolas Markis</h6> */}
                  <p>
                    In-home healthcare services can be more accessible for patients who have
                    difficulties with transportation or prefer to stay at home. This can also be
                    helpful for those who have a busy schedule or need to care for other family
                    members.
                  </p>
                </div>
                <div className="btn-wrap margin-top-30">
                  <a href="/services" className="boxed-btn round-btn">
                    Discover More
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="thumbnail">
                  <Image name="accessibility.png" />
                  {/* <img src="/img/sections/why-choose/accessibility.png" alt="img" /> */}
                  <div className="schedule-wrap">
                    <h6 className="title">Office Hours</h6>
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i>Mon - Fri : 9.00 A.M - 5.00 P.M
                      </li>
                    </ul>
                    <span className="contact-no">
                      <i className="fas fa-phone"></i>
                      <a href="tel:+13058233561">+1(305)823-3561</a>
                    </span>
                    <p className="mt-3 text-white">ONCALL SERVICES 24/7 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade show active" id="familiarity">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content-wrapper">
                  <h3 className="title">
                    Your home, our family. Caring for you with love and dedication.
                  </h3>
                  {/*       <h6 className="consultant">Therapy With Dr. Nicolas Markis</h6> */}
                  <p>
                    By having a dedicated personal staff for in-home healthcare services, you can
                    ensure that the same care providers are visiting the patient each time. This can
                    help create a warm and friendly relationship between the patient and the care
                    providers, leading to better and more comfortable treatment. Additionally, it
                    provides a more personalized experience for the patient, as they will be able to
                    develop a sense of familiarity with the same staff members over time.
                  </p>
                </div>
                <div className="btn-wrap margin-top-30">
                  <a href="/services" className="boxed-btn round-btn">
                    Discover More
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="thumbnail">
                  <Image name="familiarity.png" />

                  {/* <img src="/img/sections/why-choose/familiarity.png" alt="" /> */}
                  <div className="schedule-wrap">
                    <h6 className="title">Office Hours</h6>
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i>Mon - Fri : 9.00 A.M - 5.00 P.M
                      </li>
                    </ul>
                    <span className="contact-no">
                      <i className="fas fa-phone"></i>
                      <a href="tel:+13058233561">+1(305)823-3561</a>
                    </span>
                    <p className="mt-3 text-white">ONCALL SERVICES 24/7 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="trained-staff">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content-wrapper">
                  <h3 className="title">
                    Trusted in-home healthcare from our highly skilled staff.
                  </h3>
                  {/*  <h6 className="consultant">Therapy With Dr. Nicolas Markis</h6> */}
                  <p>
                    Our team is comprised of highly skilled and experienced healthcare
                    professionals. With their advanced training and years of experience, they are
                    trained to provide state-of-the-art care in the comfort of your home. Whether
                    you or a loved one requires in-home care services, you can rest assured that our
                    team has the knowledge and skills to provide the highest level of care.
                  </p>
                </div>
                <div className="btn-wrap margin-top-30">
                  <a href="/services" className="boxed-btn round-btn">
                    Discover More
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="thumbnail">
                  <Image name="trained-staff.png" />

                  {/* <img src="/img/sections/why-choose/trained-staff.png" alt="img" /> */}
                  <div className="schedule-wrap">
                    <h6 className="title">Office Hours</h6>
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i>Mon - Fri : 9.00 A.M - 5.00 P.M
                      </li>
                    </ul>
                    <span className="contact-no">
                      <i className="fas fa-phone"></i>
                      <a href="tel:+13058233561">+1(305)823-3561</a>
                    </span>
                    <p className="mt-3 text-white">ONCALL SERVICES 24/7 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChooseUs
