import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'

const ServicesC = () => {
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            name
            icon
            description
            descriptionDetail
          }
        }
      }
    }
  `)
  
  return (
    <section className="service-section-area home-two margin-top-90">
      <img src="/img/shapes/shape-01.png" className="service-shape-02" alt="" />
      <img src="/img/shapes/shape-01.png" className="service-shape-03" alt="" />
      <div className="container nav-container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="theme-section-title desktop-center">
              <h6 className="subtitle">Services We Provide</h6>
              <h4 className="title">Making a difference in the healthcare industry </h4>
            </div>
          </div>
        </div>
        <div className="row">
          {data.allServicesJson.edges && data.allServicesJson.edges.map(({ node },inx:number) => (
            <div key={`services-${inx}`} className="col-lg-4 col-md-6">
              <div className="service-single-item">
                <img src="/img/shapes/design.png" className="shape" alt="img" />
                <div className="icon">
                  <img src={node.icon} alt="img" />
                </div>
                <div className="content">
                  <h4 className="title">{node.name}</h4>
                  <p>{node.description}</p>
                  <Link to="/services/details" className="read-btn" state={{ showServ: node.name }}>
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ServicesC
