import * as React from 'react'
import Layout from '@/components/Layout'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'
import BannerArea from '@/components/Home/Banner'
import BannerBottomPage from '@/components/Home/BannerBottom'
import AboutHome from '@/components/Home/About'
import CounterArea from '@/components/Home/Counter'
import AboutUsArea from '@/components/Home/AboutusArea'
import WeDoArea from '@/components/Home/WeDo'
import ChooseUs from '@/components/Home/ChooseUs'
import CallToAction from '@/components/Home/CallToAction'
/* import CoursesArea from '@/components/Home/Courses' */
import TestimonialHome from '@/components/Home/Testimonial'
import TeamHome from '@/components/Home/Team'
/* import BlogHome from '@/components/Home/Blog' */
import ServicesC from '@/components/Services'
import ContactArea from '@/components/Home/Contact'
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" canonical={`${useLocation().host}`} />
      <BannerArea />
      <BannerBottomPage />
      <AboutHome />
      <CounterArea />
      <AboutUsArea />
      <WeDoArea />
      <ServicesC />
      <ChooseUs />
      <CallToAction />
      <ContactArea />
      {/*  
      <CoursesArea />
      <TestimonialHome />
      <TeamHome />
      <BlogHome /> 
       */}
    </Layout>
  )
}

export default IndexPage
