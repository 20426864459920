import Image from '@/components/Image'
import React from 'react'

const AboutUsArea = () => {
  return (
    <section className="about-us-section">
      <div className="container nav-container">
        <div className="row">
          <div className="col-lg-6">
            <div className="content-wrap">
              <div className="theme-section-title style-01">
                <h6 className="subtitle">WHO WE ARE</h6>
                <h4 className="title">Trusted caregivers, committed to improving lives.</h4>
                <p>
                  At Perfect Care, we are passionate about providing exceptional and personalized
                  care services for each of our clients. Our highly trained and dedicated team of
                  caregivers is committed to providing compassionate and professional care with
                  every service. We strive to offer innovative and flexible care solutions designed
                  to meet the unique needs and preferences of each individual. We take pride in
                  helping families find the peace of mind and comfort they need by trusting us with
                  the care of their loved ones.
                </p>
              </div>
              <div className="check-list-items">
                <ul className="list-parent">
                  <li className="list-child">Experienced caregivers</li>
                  <li className="list-child">Compassionate care services</li>
                  <li className="list-child">Innovative solutions</li>
                </ul>
                <ul className="list-parent">
                  <li className="list-child">Professionalism and dedication</li>
                  <li className="list-child">Personalized approach</li>
                  <li className="list-child">Peace of mind</li>
                </ul>
              </div>
              <div className="btn-wrap margin-top-30">
                <a href="/services" className="boxed-btn round-btn">
                  Discover More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="thumbnail position-relative">
              {/* <img src="/img/sections/about/about-04.png" alt="Image" /> */}
              <Image name='about-04.png'/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUsArea
